import React from "react"
import { PageLayout } from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { PostListItem } from "../components/postlist"


const IndexPage = ({ data: { allMdx: { edges } } }) => {
  const posts = edges.filter(({ node }) => !node.frontmatter.draft)
  return (
    <PageLayout>
      <Seo title="首页" />
      <div className="main flex flex-col space-y-6">
        {
          posts.map(({ node }) => (
            <PostListItem 
              slug={node.fields.slug} title={node.frontmatter.title}
              excerpt={node.excerpt} date={node.frontmatter.date}
            />
          ))
        }
      </div>
    </PageLayout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  {
    allMdx(filter: {slug: {regex: "/posts/"}}, sort: {fields: [frontmatter___date], order: DESC}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
            draft
          }
        }
      }
    }
  }
`