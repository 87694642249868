import React from "react"
import { Link } from "gatsby"
import BlogStat from "../components/blogStat"


export const PostListItem = ({ slug, title, excerpt, date, tags }) => {
  return (
    <div className="card">
      <Link to={slug} className="flex flex-col space-y-4">
        <p className="text-xl font-bold hover:opacity-75">
          {title}
        </p>
        <p >{excerpt}</p>
        <BlogStat date={date} />
      </Link>
    </div>
  )
}